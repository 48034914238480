import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

// Assets
import background from "../../assets/backgrounds/evidence-collection-bg.png";

// Desktop Backgrounds
import supportingBg from "../../assets/backgrounds/supporting-evidence-bg.png";
import injuriesBg from "../../assets/backgrounds/evidence-injuries-bg.png";
import recordsBg from "../../assets/backgrounds/medical-records-bg.png";
import infoBg from "../../assets/backgrounds/info-requests-bg.png";

// Mobile Backgrounds
import incidentMobileBg from "../../assets/backgrounds/incident-mobile-bg.png";
import supportingMobileBg from "../../assets/backgrounds/supporting-mobile-bg.png";
import injuriesMobileBg from "../../assets/backgrounds/injuries-mobile-bg.png";
import recordsMobileBg from "../../assets/backgrounds/records-mobile-bg.png";
import infoMobileBg from "../../assets/backgrounds/info-mobile-bg.png";
import storingBg1 from "../../assets/backgrounds/storingBg1.svg";
import storingBg2 from "../../assets/backgrounds/storingBg2.svg";

// Components
import Section from "../Section/Section";
import Text, { types } from "../Text/Text";

// Contents
import EvidenceCollectionDesktop from "./EvidenceCollectionDesktop";
import IncidentDetailsContent from "./contents/IncidentDetailsContent";
import HomeSubsectionResponsive from "../HomeSubsection/HomeSubsectionResponsive";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    minHeight: 1500,
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
    width: "100vw",
    paddingRight: "2.4vw",
    paddingLeft: "2.4vw",
    [theme.breakpoints.down("md")]: {
      minHeight: 1100,
      paddingBottom: 40
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      backgroundImage: "none"
    }
  },
  storingBg1: {
    pointerEvents: "none",
    position: "absolute",
    top: -10,
    left: -40
  },
  storingBg2: {
    pointerEvents: "none",
    position: "absolute",
    top: 360,
    right: 0,
    transform: "translateX(70px)"
  },
  mainTitle: {
    maxWidth: 700,
    textAlign: "center",
    zIndex: 99
  }
}));

const subsections = [
  {
    id: "incident",
    heading: "Incident Details",
    description: "Tell us what happened",
    content: <IncidentDetailsContent />,
    title: "Document what happened, when and where",
    descriptions: ["All the important details can be stored"],
    mobileBg: incidentMobileBg
  },
  {
    id: "evidence",
    heading: "Supporting Evidence",
    description: "Upload any and all supporting evidence",
    title:
      "Any file, any size.\nUpload it to make sure it’s safe, secure and holds evidential weight",
    descriptions: [
      "Kulpa captures and stores all metadata for uploaded files. With our BS10008 accreditation, any evidence submitted via the app is legally admissible in civil and criminal proceedings. This often eliminates the need for police to download the entire content of a user’s device.",
      "Upload images, videos, audio, messages, documents, CCTV, or other files to Kulpa. For advice on uploads, contact us via email or the chat bubble."
    ],
    background: supportingBg,
    mobileBg: supportingMobileBg
  },
  {
    id: "injuries",
    heading: "Physical Injuries",
    description: "Add Injury",
    title:
      "Use our forensic technology to take scaled photographs of any physical injuries",
    descriptions: [
      "Kulpa’s proprietary technology enables anyone with a camera phone and a card to capture forensic imagery. Photographs of physical injuries taken with the app are legally admissible as forensic evidence. The in-app camera applies a scale accurate to 1 millimeter, meeting legal requirements for forensic imagery. Additionally, any photos or videos taken with the in-app camera are not stored locally on your device, ensuring they remain private."
    ],
    background: injuriesBg,
    mobileBg: injuriesMobileBg
  },
  {
    id: "records",
    heading: "Medical Records",
    description: "Add relevant medical records",
    title: "Upload any relevant medical records, notes or proof of treatment",
    descriptions: [
      "Medical records provide strong corroborating evidence. By uploading relevant records to the Kulpa app, you ensure they are safe, secure, and available to support your case in civil or criminal proceedings. Obtaining records from a health provider can be time-consuming, even with consent, so uploading them yourself significantly speeds up the process.",
      "You can photograph the records or proof of treatment using the in-app camera or upload them as a file. All file types are accepted, including PDF, DOC, JPG, and PNG."
    ],
    background: recordsBg,
    mobileBg: recordsMobileBg
  },
  {
    id: "info",
    heading: "Information Requests",
    description:
      "Additional information can be requested from other case contributors",
    title:
      "Invite others to contribute evidence. You can also chat to them using the app’s secure chat function",
    descriptions: [
      "Other people may have evidence to support your case. It’s important to upload this evidence to the Kulpa cloud to ensure it’s verifiable, holds evidential weight, and is legally admissible.",
      "Invite contributors to your case, who will receive a notification. Once accepted, you can chat securely and they can upload the needed evidence. The best upload comes from the device where the evidence originated. For audio or video files, use the original phone. For CCTV, use the Kulpa web app. For WhatsApp conversations, export the chat and upload the zip file",
      "For advice on uploads, contact us via email or the chat bubble."
    ],
    background: infoBg,
    mobileBg: infoMobileBg
  }
];

const mainTitle = `We've Made Evidence Collection Easy`;
const mainDescription = `Here's what you can privately store`;

const EvidenceCollectionSection = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Section className={classes.root} id="evidence">
      {isMobile && (
        <>
          <img src={storingBg1} alt="" className={classes.storingBg1} />
          <img src={storingBg2} alt="" className={classes.storingBg2} />
        </>
      )}
      <Text
        type={types.miniSectionTitle}
        className={classes.mainTitle}
        component="h2"
      >
        {mainTitle}
      </Text>
      <Text type={types.titleDescription} component="p">
        {mainDescription}
      </Text>
      {isMobile ? (
        <HomeSubsectionResponsive subsections={subsections} />
      ) : (
        <EvidenceCollectionDesktop subsections={subsections} />
      )}
    </Section>
  );
};

export default EvidenceCollectionSection;
