/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import get from "lodash/get";
import React from "react";
import HighlightColor from "../components/HighlightColor/index";
import SimpleLink from "../components/SimpleLink/SimpleLink";

export const lanIds = {
  en: "en"
};

const defaultLan = lanIds.en;

const loadThumbnail = (countryId, id) => {
  const img = require(`../assets/videoThumbnails/${countryId}/${id}`);
  return img;
};

const loadBadge = (countryId, id) => {
  const img = require(`../assets/badges/${countryId}/${id}`);
  return img;
};

export const language = {
  header: {
    howToCapture: {
      [lanIds.en]: "How To Capture"
    },
    faqs: {
      [lanIds.en]: "FAQs"
    },
    victimSupport: {
      [lanIds.en]: "Support Services"
    },
    technicalSupport: {
      [lanIds.en]: "Technical Support"
    },
    mobile: {
      menu: {
        [lanIds.en]: "Menu"
      },
      social: {
        [lanIds.en]: "Social"
      }
    }
  },
  footer: {
    victimSupport: {
      support: {
        [lanIds.en]: "Support Services"
      }
    },
    news: {
      pressRelease: {
        [lanIds.en]: "Press Releases"
      }
    },
    tech: {
      title: {
        [lanIds.en]: "Site"
      },
      issue: {
        [lanIds.en]: "Technical Support"
      },
      faq: {
        [lanIds.en]: "FAQs"
      }
    },
    appsInfo: {
      title: {
        [lanIds.en]: "Download the app or access your account on the web."
      },
      description: {
        [lanIds.en]: "Put justice in your hands."
      },
      designProtectionA: {
        [lanIds.en]:
          "EU registered design nos. 007982194-0001 through 007982194-0007"
      },
      designProtectionB: {
        [lanIds.en]:
          "UK registered design nos. 90079821940001 through 90079821940007"
      },
      designProtectionC: {
        [lanIds.en]: "Patent granted No. GB 2595260 B"
      },
      designProtectionD: {
        [lanIds.en]: "ISO 27001:2022 IS 739859"
      },
      designProtectionE: {
        [lanIds.en]: "BS 10008:2020 EIMS 739858"
      }
    },
    madeWith: {
      with: {
        [lanIds.en]: (
          <span>
            © {new Date().getFullYear()} <HighlightColor>kulpa</HighlightColor>{" "}
            limited
          </span>
        )
      }
    },
    techUk: {
      title: {
        [lanIds.en]: "Proud Member of:"
      }
    },
    platform: {
      title: {
        [lanIds.en]: "Platform"
      },
      copywright: {
        [lanIds.en]: `© ${new Date().getFullYear()} kulpa limited`
      }
    },
    legal: {
      title: {
        [lanIds.en]: "Legal"
      },
      termsAndConditions: {
        [lanIds.en]: "Terms and Conditions"
      },
      privacyPolicy: {
        [lanIds.en]: "Privacy Policy"
      },
      certificates: {
        [lanIds.en]: "Accreditation Certificates"
      }
    },
    certificates: {
      title: {
        [lanIds.en]: "Partners and Certifications"
      }
    }
  },
  pages: {
    home: {
      intro: {
        description: {
          [lanIds.en]:
            "Victims, their friends and relatives and the wider public can now use the Kulpa app to record and report violent crime including assault, domestic abuse and sexual offences."
        },
        getNotified: {
          [lanIds.en]: "Get notified of the release"
        },
        addEmail: {
          [lanIds.en]: "Add your email here"
        },
        download: {
          [lanIds.en]: "Download the app"
        },
        downloadDescription: {
          [lanIds.en]:
            "To quickly download the app on your phone, open your camera and point it towards the QR code below."
        }
      },
      campaign: {
        title: {
          [lanIds.en]: "Evidence is Everything"
        },
        description: {
          [lanIds.en]: (
            <>
              Use <b>kulpa</b> to securely store legally admissible evidence
            </>
          )
        }
      },
      tutorial: {
        banner: {
          [lanIds.en]:
            "Empowering victims, enhancing evidence, boosting conviction rates and building trust."
        },
        tutorialCapture: {
          title: {
            [lanIds.en]: "Capture the Injuries"
          },
          description: {
            [lanIds.en]: "Sound evidence, captured in a matter of minutes"
          }
        },
        tutorialUpload: {
          title: {
            [lanIds.en]: "Upload supporting media"
          },
          description: {
            [lanIds.en]: "Upload scene images, videos medical records, etc."
          }
        },
        tutorialInvite: {
          title: {
            [lanIds.en]: "Invite others to contribute"
          },
          description: {
            [lanIds.en]: "Enhancing evidence collection through collaboration."
          }
        },
        tutorialSubmit: {
          title: {
            [lanIds.en]: "Submit to the Police"
          },
          description: {
            [lanIds.en]:
              "This forensic evidence can then be used to swiftly prosecute those responsible."
          }
        }
      },
      journey: {
        title: {
          [lanIds.en]: "Start Your Journey"
        },
        description: {
          [lanIds.en]: "Use Kulpa To Securely Store Legally Admissible Evidence"
        },
        getApp: {
          [lanIds.en]: (
            <span>
              Get the free <b>kulpa</b> app
            </span>
          )
        }
      }
    },
    howToCapture: {
      intro: {
        title: {
          [lanIds.en]: "How to Capture"
        },
        description: {
          [lanIds.en]:
            "Use the Kulpa app to record and securely store legally admissible forensic evidence in just a few taps.​ ​It’s up to you, if and when, you choose to formally report the crime and submit the evidence to the police.\n​But whether you choose to submit the evidence you capture with this app, today, tomorrow or in a few years time, it will be evidentially admissible and able to support a swift investigation and hopefully prosecution.\n​When you’re feeling ready to say something, protection, safety and justice are just a few taps away."
        }
      },
      tutorialVideos: {
        tutorials: [
          {
            title: {
              [lanIds.en]: "On-boarding through Kulpa app"
            },
            description: {
              [lanIds.en]:
                "Sound evidence, captured in a matter of minutes, Sound captured in a matter of minutes evidence, captured in a captured."
            },
            icon: {
              [lanIds.en]: loadThumbnail(lanIds.en, "Thumbnail.png")
            },
            src: {
              [lanIds.en]:
                "https://mediainjurycapture-ukso1.streaming.media.azure.net/2cfb0bdf-63b4-4355-b024-37d69200d976/IC_INTRO_F.ism/manifest"
            }
          },
          {
            title: {
              [lanIds.en]: "On-boarding through Kulpa app 2"
            },
            description: {
              [lanIds.en]:
                "Sound evidence, captured in a matter of minutes, Sound captured in a matter of minutes evidence, captured in a captured."
            },
            icon: {
              [lanIds.en]: loadThumbnail(lanIds.en, "Thumbnail.png")
            },
            src: {
              [lanIds.en]:
                "https://mediainjurycapture-ukso1.streaming.media.azure.net/2cfb0bdf-63b4-4355-b024-37d69200d976/IC_INTRO_F.ism/manifest"
            }
          }
        ],
        modal: {
          next: {
            [lanIds.en]: "Next"
          },
          previous: {
            [lanIds.en]: "Previous"
          }
        }
      }
    },
    victimSupport: {
      intro: {
        title: {
          [lanIds.en]: "Support Services"
        },
        description: {
          [lanIds.en]: "Help is available"
        },
        supportLines: {
          [lanIds.en]: "Support Lines"
        }
      },
      supportLine: {
        victimSupport: {
          [lanIds.en]: "Support Services"
        }
      },
      requestBox: {
        requests: {
          organization: {
            title: {
              [lanIds.en]:
                "Does Your Organization Provide Victim Support Services?"
            },
            description: {
              [lanIds.en]:
                "If you’re not currently on the list, we’d love to add you."
            },
            button: {
              [lanIds.en]: "Request Inclusion"
            }
          },
          raiseAwareness: {
            title: {
              [lanIds.en]:
                "Would you like to help us to help others by raising awareness of the app?"
            },
            description: {
              [lanIds.en]:
                "We would be happy to send you some free promotional material."
            },
            button: {
              [lanIds.en]: "Request promo material"
            }
          },
          cantFind: {
            title: {
              [lanIds.en]: "Can't find what you are looking for?"
            },
            description: {
              [lanIds.en]:
                "We will help you find the answer or connect with an advisor."
            },
            button: {
              [lanIds.en]: "Contact us"
            }
          }
        }
      }
    },
    faq: {
      intro: {
        title: {
          [lanIds.en]: "FAQ´S"
        },
        description: {
          [lanIds.en]: "Here are the answers"
        },
        subTitle: {
          [lanIds.en]: "Frequently asked Questions"
        }
      },
      questions: [
        {
          title: {
            [lanIds.en]:
              "I am worried about having the app on my own personal device - what are my options? Can I 'hide' the app?"
          },
          description: {
            [lanIds.en]: `
              What you can do in terms of 'hiding' any app will depend on your device and operating system (iOS or Android) some allow 'hiding' others don't. 

              But we've taken several steps to minimise the risks and assist victims:

              (a) The app will appear only as 'Capture'

              (b) On iOS you can 'Remove from Home Screen' by pressing and holding down the icon and then clicking 'Remove App'. The app will however still appear in your Settings list, if someone is really looking for it.

              (c) On some Android devices you can 'hide the app' but again if you use the search function, it will still appear in the list of installed applications.

              So we've got two other options for you
              
              (1) If you delete the app, it will not delete your account and data. Data can only be deleted from inside the app and the app is protected by an individual PIN which is chosen by the user on registration. It can also be protected by Face ID.
              
              Therefore, you can download the app, record and store the evidence, and then delete the app again. The data will be retained and available when you re-download and log back in. Or you can access the data by logging in using the web-app which is available via our website using your computer's web browser.  
              
              (2) If you do not feel comfortable downloading and using the app on your own device, you can ask a friend, relative or support worker to download the app, register in that capacity and use the app to help you to capture and record all of the evidence. It will be stored safely and securely on their account, ready, if and when, you want them to submit it to the police.
              `
          }
        },
        {
          title: {
            [lanIds.en]: "Is the app free?"
          },
          description: {
            [lanIds.en]: `
              Yes, the app is completely free to download and use. It can be downloaded from either the App Store or Google Play.
              
              It can also be accessed via your computer browser, visit the injurycapture.com website and then click login (top right-hand corner).
              
              Anyone can use the app to take photographs of injuries which are scaled in line with the legal requirements to qualify as forensic evidence. Users can also capture and store all manner of supporting evidence, including photographs, videos, audio recordings, CCTV, medical records, notes, journals and any other relevant file or media format.
              `
          }
        },
        {
          title: {
            [lanIds.en]: "Can I use the app anywhere in the world?"
          },
          description: {
            [lanIds.en]: `
              Yes. Wherever they are in the world, any victim, witness, friend, relative, supporter, police officer or medical professional can download and use the app free of charge.
              
              When a user chooses to submit, the evidence is automatically organised into a report format compatible with the relevant local laws and police force systems, and is then sent to the relevant authority.
              
              You can access the Kulpa app via your mobile device or through your computer browser.\n\nIt is important to us that everybody has the ability to put justice in their hands.
              `
          }
        },
        {
          title: {
            [lanIds.en]:
              "What do you do with my data once it’s saved on your cloud?"
          },
          description: {
            [lanIds.en]: `
              Quite simply nothing.

              Any data captured in or uploaded to the app is safely secured on our secure cloud servers all of which are internationally accredited for their information security (ISO/IEC 270001).

              Your data will not be shared with anyone, unless you choose to submit it to the police or another agency via the app.

              If you have not submitted the data you can delete it at any time.

              Ultimately, it’s your data and you have complete control. You choose what happens to it and what it’s used for. But whatever you choose, it will be safe and secure.
              `
          }
        },
        {
          title: {
            [lanIds.en]:
              "Why can't I just take ordinary pictures and store them and the other evidence on my phone, tablet, or computer?"
          },
          description: {
            [lanIds.en]: `
              Ordinary photographs won't have a scale and will, therefore, not be best evidence. They may be challenged or discredited. 

              If you lose your phone or, for some reason, have to delete the data, the evidence you have stored on your camera roll etc will be lost. 

              Even if you do transfer your ordinary photos and files to the police, perhaps via email or another form of data transfer, the evidence won't be immediately legally admissible, which means that the police will likely have to download your entire device to verify the authenticity of the evidence initially provided.

              But with kulpa…
              
              Photographs captured with the app will be admissible as forensic evidence, in the form of forensic imagery. Why? Because the in-app camera uses technology that enables a scale accurate to 1 millimetre to be applied to the image.
              
              Forensic photographs that used to require a Crime Scene Investigator with a big camera and a ruler can now be taken by anyone with a mobile phone, using the Kulpa app.
              
              Any photographs or videos captured with the apps in-app camera will not be stored locally on your device and so there is no risk that they will be seen by others.
              
              The data is stored safely and securely in our cloud, accessible only to you, for as long as you need, until you are ready to report it to the police. There is no need to worry about losing the evidence or trying to 'hide' it on your device.
              
              Any and all evidence saved to and submitted via the Kulpa app is verified at source using its metadata. It is therefore immediately legally admissible as authenticated evidence. In a majority of cases, the police will not need to undertake a full download of your personal device.
              
              When you're ready, you can report the crime and transfer the evidence to the police in just a couple of taps. The relevant police force will receive an immediately legally admissible package of evidence, which will enable them to quickly investigate, interview and hopefully charge and prosecute the offender.
              
              The app is there for you, until you're ready. Once you are, safety and justice are now just a few taps away.
              `
          }
        },
        {
          title: {
            [lanIds.en]:
              "Will the police still need to download my mobile phone if I use the app to report a crime and submit evidence?"
          },
          description: {
            [lanIds.en]: `
              No, not in most cases.

              The police download phones in order to verify and formally exhibit the evidence which is stored on the device and which is relevant to the investigation.
              
              But, the Kulpa app is accredited by the United Kingdom Accreditation Service (UKAS) for Standard BS10008 - Evidential Weight and Legal Admissibility of Electronic Information.

              This means that any and all evidence saved to and submitted via the app is already verified, is immediately legally admissible and is automatically exhibited in a statement which is compliant with both civil and criminal court proceedings.

              The app captures all of the required metadata and therefore, in a majority of cases, a physical download of the entire device will not be required.

              The only time it may be required, as a precaution, is if the initial investigation finds cause for concern.

              Additionally, any photographs of physical injuries captured within the app, also qualify as forensic evidence, due to the fact that the in-app camera enables the images to have an accurate scale applied to them.

              Now, victims can have the confidence to save evidence and report the crime when they are ready, without the risk that their entire device will be downloaded and their entire life and personal information unnecessarily exposed. 

              Remember, the Kulpa app can be used to capture, save and securely store all electronic information associated with violent crimes, including, scaled forensic images of injuries, media such as photographs, videos, voice recordings, messages, CCTV or any other file type, medical records, notes, journals, statements and more...
              `
          }
        },
        {
          title: {
            [lanIds.en]:
              "How can you be sure that the evidence reported via the app is true, accurate and authentic, and is not false or misrepresented?"
          },
          description: {
            [lanIds.en]: `
              The Kulpa app collects all of the meta data from any and all evidence captured and stored. Therefore, it is all verified at source. If there are any questions regarding the authenticity of the evidence submitted, then these can be easily reviewed by a professional. 
              
              In addition, the app enables a person to capture scaled forensic imagery of the injuries. If there is any dispute regarding causation, then these images can be reviewed by a medical expert. 

              In summary, this app will ensure that those who genuinely commit violent offences can - and will - be prosecuted, and victims safeguarded form any further harm. 

              If you want to make a false accusation then it is best not to do it thought this app. 

              There remains a public impression that false allegations are common and that innocent people suffer as the result of being wrongfully accused. But the verified statistics do not support this. Research data from the UK, Europe and the US suggests that only 2-6% of accusations made in relation to sexual violence or abuse are found or suspected to be false, and this is likely to be an overstated figure.

              Globally over 25% of people will experience abuse or sexual violence during their lifetime and we must empower them to collect the evidence that will enable policing to prosecute the offenders and safeguard victims and our wider communities.
              `
          }
        },
        {
          title: {
            [lanIds.en]: "How does Kulpa make money?"
          },
          description: {
            [lanIds.en]: `
              The app is free for anyone to download and use, and in smaller geographies the data generated can be easily absorbed by any agency. However, police forces and government authorities do have the option to connect automatically to our output via an API and subscribe to our paid services, both of which deliver enhanced efficiencies and additional benefits.

              Essentially, governments pay us for our enhanced technology because it helps them to quickly protect victims, convict offenders and in the longer term, reduce crime rates. The result of which is that their communities are safer, public spending is reduced and economic prosperity increased.
              `
          }
        },
        {
          title: {
            [lanIds.en]: "What happens to images I take within the app?"
          },
          description: {
            [lanIds.en]: `
              Any photographs or videos captured with the in-app camera will not be stored locally on your device and so there is no risk that they will be inadvertently seen by others.

              Instead, the images along with any other data you upload, are stored safely and securely in our cloud which is accredited for its information security.

              Data will not be shared with any authority until you choose to press submit and then reconfirm this action.
              `
          }
        },
        {
          title: {
            [lanIds.en]: "How old do I need to be to use Kulpa?"
          },
          description: {
            [lanIds.en]:
              "To us the App and create an account you must be 13 years of age or older and agree to our Terms and Privacy Notice."
          }
        },
        {
          title: {
            [lanIds.en]:
              "My child/young relative is a victim of a violent crime, can I use this app to record their injuries?"
          },
          description: {
            [lanIds.en]: "Yes, as long as the user is over the age of 13."
          }
        },
        {
          title: {
            [lanIds.en]:
              "Can I get a friend or family member to help me report my case?"
          },
          description: {
            [lanIds.en]:
              "Yes, a trusted friend or family member can download the app and help in submitting the case. When they tap ‘start a new case’ they just need to select ‘I am a relative of the victim reporting the case on their behalf’"
          }
        },
        {
          title: {
            [lanIds.en]: "Who can see my personal information?"
          },
          description: {
            [lanIds.en]:
              "We only use any personal details and data we collect through the App and its Services in the ways set out in our terms and privacy policy. Once you submit a case, the relevant police force will have access to your information and they may also pass it to associated authorities, in order that they may fully investigate the crime and where possible, prosecute those responsible. In addition, any medical professionals or police officers added to the case as contributors will have full access. A contributor added as a witness will only be able to see the case summary and contribute data, nothing more."
          }
        },
        {
          title: {
            [lanIds.en]: "Can I change my victim ID picture?"
          },
          description: {
            [lanIds.en]:
              "No, this image is used to verify your identity and so changing it is not permitted, so please ensure it is appropriate."
          }
        },
        {
          title: {
            [lanIds.en]: "I keep being logged out of the web-app"
          },
          description: {
            [lanIds.en]:
              "For your safety and the security of your information, there are timed sessions which means after a certain amount of time the web-app will automatically log you out. All you need to do is simply log back in."
          }
        },
        {
          title: {
            [lanIds.en]: "What do I do if I have lost my phone?"
          },
          description: {
            [lanIds.en]:
              "Your account is secured using your mobile phone number so you will need to obtain a new SIM card with the same number before you can access your account. But in the meantime, your data is safe and secure and will still be there on your return."
          }
        },
        {
          title: {
            [lanIds.en]: "Can I make more than one account on one phone?"
          },
          description: {
            [lanIds.en]:
              "No, each account is verified against a mobile phone number."
          }
        },
        {
          title: {
            [lanIds.en]: "Is my phone supported?"
          },
          description: {
            [lanIds.en]:
              "Most likely yes, but a full list of supported devices can be found at https://www.injurycapture.com/termsAndConditions/victim#pageStart Schedule 1"
          }
        },
        {
          title: {
            [lanIds.en]: "Can I turn my notifications off?"
          },
          description: {
            [lanIds.en]:
              "Yes, you can customise when and how you receive notifications. Go to the settings menu in either mobile or web app to change them."
          }
        },
        {
          title: {
            [lanIds.en]: "Can I remove/delete my case?"
          },
          description: {
            [lanIds.en]:
              "You can delete your case at any time prior to submitting it to the police. Once submitted the case cannot be deleted. Should you wish to withdraw your case please contact the officer assigned. The officer can be contacted using the app. Tap on the officer’s name in the case summary screen to start a chat with them."
          }
        },
        {
          title: {
            [lanIds.en]: "I've forgotten how to capture"
          },
          description: {
            [lanIds.en]:
              "Full help videos are available at https://www.injurycapture.com/howtocapture#pageStart"
          }
        },
        {
          title: {
            [lanIds.en]:
              "What kind of card can I use to take images of the injuries?"
          },
          description: {
            [lanIds.en]: `
              You can use any card which is the same size as a standard debit or credit card. Typically, this will include a bank card, a driving licence, ID card, Membership card, Loyalty card, Travelcard, etc.

              The card will be blurred out by the system so don't worry, any personal or financial details present on the card will not be shared or displayed.
              `
          }
        },
        {
          title: {
            [lanIds.en]:
              "If I use a debit or credit card as a reference object when taking pictures of an injury, will everyone see my card details?"
          },
          description: {
            [lanIds.en]:
              "No, as soon as you have taken the images and tapped the ‘done’ button, our software blurs out any details on the card so they will not been seen by anyone.\n Once you have saved an injury tap back into it and you will see that the card details are blured and unreadable."
          }
        },
        {
          title: {
            [lanIds.en]: "My injury spans longer/is larger than the card"
          },
          description: {
            [lanIds.en]:
              "This does not matter as long as the card is held by a section of the injury, flat and in contact with the skin. See the in-app instructions for further details.\nhttps://www.injurycapture.com/howtocapture#pageStart"
          }
        },
        {
          title: {
            [lanIds.en]: "My injury is in a place I cannot reach with my camera"
          },
          description: {
            [lanIds.en]:
              "In instances such as these, we recommend requesting assistance from a trusted family member or friend."
          }
        },
        {
          title: {
            [lanIds.en]:
              "Can I take pictures of my injury if the incident happened a long time ago?"
          },
          description: {
            [lanIds.en]:
              "If there is scarring or remnants of the injury left, we still encourage you to take photographs. In the description box it would be helpful to provide a rough estimate of how old you believe the injuries are."
          }
        },
        {
          title: {
            [lanIds.en]: "I am not sure which treatment I received"
          },
          description: {
            [lanIds.en]:
              "Fill out the boxes with as much information as possible. Any missing details can be requested from the appropriate party."
          }
        },
        {
          title: {
            [lanIds.en]: "I do not have access to my medical records"
          },
          description: {
            [lanIds.en]:
              "This is not a problem; this can be requested from the appropriate medical party once the case has been submitted."
          }
        },
        {
          title: {
            [lanIds.en]:
              "Is there a limit to the number of people that can be invited to contribute to a case?"
          },
          description: {
            [lanIds.en]: "No, you can invite as many people as you need."
          }
        },
        {
          title: {
            [lanIds.en]: "I have entered the wrong details"
          },
          description: {
            [lanIds.en]:
              "Any and all details can be changed or edited before you submit. Once submitted, if you notice any errors please contact our technical team. Or you can ‘add further descriptions’ in most sections where you could explain the incorrect details and provide additional information to correct that entered previously."
          }
        },
        {
          title: {
            [lanIds.en]: "What type of files can I upload?"
          },
          description: {
            [lanIds.en]:
              "We support almost all file types with the exception of .zip and .ext files."
          }
        },
        {
          title: {
            [lanIds.en]: "I have uploaded the wrong media file/pdf"
          },
          description: {
            [lanIds.en]:
              "If the case has not been submitted, simply delete the file and upload another using either the mobile or web-app. Once a case is submitted any media associated with it cannot be deleted and any further uploads will also be permanent to ensure the integrity of evidence. If you require further support contact our support team."
          }
        },
        {
          title: {
            [lanIds.en]: "Why can I not submit my case?"
          },
          description: {
            [lanIds.en]:
              "You will need to provide details of the incident before you can submit a case. The app will prompt you to complete these details if you have not already done so."
          }
        },
        {
          title: {
            [lanIds.en]: "How long can I keep a case unsubmitted for?"
          },
          description: {
            [lanIds.en]:
              "Currently, a case can remain unsubmitted and in draft form indefinitely. The data and information will be safely and securely maintained until you are ready to submit."
          }
        },
        {
          title: {
            [lanIds.en]: "What happens when I submit a case, what can I expect?"
          },
          description: {
            [lanIds.en]:
              "Once you have submitted a case it will be assigned to your local police force. An officer will be assigned to investigate and they will seek to contact you as soon as possible."
          }
        },
        {
          title: {
            [lanIds.en]: "Will using Kulpa speed up my case?"
          },
          description: {
            [lanIds.en]:
              "Yes, having all of the evidence in one place means the police will need to spend less time collating evidence and more time progressing other aspects of the case. Also, the more evidence there is, the more likely a suspect will be arrested, charged and successfully prosecuted."
          }
        },
        {
          title: {
            [lanIds.en]: "Can I use this app while abroad?"
          },
          description: {
            [lanIds.en]:
              "Yes, you can use the app to capture evidence anywhere in the world. It is however the location of the crime which defines which authority will investigate it and therefore if you suffer injuries outside of your country of residence, please contact our support team so that we can decide with you, which authority the case should be submitted to."
          }
        }
      ]
    },
    contactSupport: {
      intro: {
        title: {
          [lanIds.en]: "Technical Support"
        },
        description: {
          [lanIds.en]: "Get in touch"
        }
      },
      contactEmail: {
        userType: {
          [lanIds.en]: "User Type"
        },
        firstName: {
          [lanIds.en]: "First Name"
        },
        lastName: {
          [lanIds.en]: "Last Name"
        },
        emailAdress: {
          [lanIds.en]: "Email Address"
        },
        phoneNumber: {
          [lanIds.en]: "Phone Number"
        },
        message: {
          [lanIds.en]: "Drop us a line"
        },
        send: {
          [lanIds.en]: "Send"
        },
        sent: {
          [lanIds.en]: "Sent"
        }
      }
    },
    promotionalMaterial: {
      intro: {
        title: {
          [lanIds.en]: "Promotional Material Request"
        },
        subtitle: {
          [lanIds.en]: "Promotional material request form"
        },
        description: {
          [lanIds.en]: "Let us know what you would like"
        }
      },
      form: {
        organization: {
          [lanIds.en]: "Organization Name"
        },
        emailAdress: {
          [lanIds.en]: "Email Address"
        },
        phoneNumber: {
          [lanIds.en]: "Contact Phone Number"
        },
        adress: {
          [lanIds.en]: "Postal Address for Delivery"
        },
        website: {
          [lanIds.en]: "Website"
        },
        descriptions: {
          [lanIds.en]: "What sort of materials would you like?"
        },
        descriptionsDescription: {
          [lanIds.en]:
            "We can make anything, so just let us know what you think would work best"
        },
        descriptionsPlaceholder: {
          [lanIds.en]:
            "ie. A5 table leaflets, A3 posters, A4 posters, Roll-up banners, Stickers, Beer mats…"
        },
        send: {
          [lanIds.en]: "Send"
        },
        sent: {
          [lanIds.en]: "Sent"
        }
      }
    },
    requestInclusion: {
      intro: {
        title: {
          [lanIds.en]: "REQUEST INCLUSION"
        },
        description: {
          [lanIds.en]:
            "Consent form for inclusion in our support service listings"
        },
        information: {
          [lanIds.en]:
            "Please provide the following details which may be included within the Support Services section of the kulpa App and other digital and print media published by Kulpa Limited."
        }
      },
      form: {
        logo: {
          [lanIds.en]: "Logo"
        },
        logoPlaceholder: {
          [lanIds.en]: "Please provide a .png or vector file"
        },
        organization: {
          [lanIds.en]: "Organization Name"
        },
        emailAdress: {
          [lanIds.en]: "Email Address"
        },
        phoneNumber: {
          [lanIds.en]: "Contact Phone Number"
        },
        address: {
          [lanIds.en]: "Postal Address for Delivery"
        },
        publicAddress: {
          [lanIds.en]: "Public Postal Address (optional)"
        },
        descriptionShort: {
          [lanIds.en]: "Short description of your services"
        },
        descriptionShortPlaceholder: {
          [lanIds.en]: "Max 12 words"
        },
        descriptionLong: {
          [lanIds.en]: "Long description of your services"
        },
        descriptionLongPlaceholder: {
          [lanIds.en]: "Max 60 words"
        },
        website: {
          [lanIds.en]: "Website"
        },
        region: {
          [lanIds.en]: "In which regions do you provide support services"
        },
        regionPlaceholder: {
          [lanIds.en]: "Please complete being specific to a regional level"
        },
        regionPlaceholderMobile: {
          [lanIds.en]: "Please be specific"
        },
        information: {
          [lanIds.en]: "An Information or support document (if available)"
        },
        informationPlaceholder: {
          [lanIds.en]: "Please provide a .pdf document"
        },
        givenName: {
          [lanIds.en]: "First name of authorizing person"
        },
        lastName: {
          [lanIds.en]: "Last name of authorizing person"
        },
        postion: {
          [lanIds.en]: "Position within the organization"
        },
        send: {
          [lanIds.en]: "Send"
        },
        sent: {
          [lanIds.en]: "Sent"
        }
      },
      notice: {
        title: {
          [lanIds.en]: "Notice"
        },
        description: {
          [lanIds.en]: (
            <span>
              You have the right to withdraw this consent at any time. Should
              you wish to do so, please send your request in writing to{" "}
              <SimpleLink>admin@kulpacloud.com</SimpleLink>. Once confirmation
              of receipt of cessation has been returned we ask that you allow
              Kulpa Limited up to 14 days to remove the references from our
              digital media and applications. Whilst existing print media cannot
              be withdrawn, no further print media will be produced from the
              date on which the confirmation of receipt of the cessation order
              was provided
            </span>
          )
        }
      }
    },
    termsAndConditions: {
      intro: {
        title: {
          [lanIds.en]: "Terms & Conditions"
        },
        description: {
          [lanIds.en]:
            "Use the Kulpa app to record and securely store legally admissible forensic evidence in just a few taps.​ ​It’s up to you, if and when, you choose to formally report the crime and submit the evidence to the police.\n​But whether you choose to submit the evidence you capture with this app, today, tomorrow or in a few years time, it will be evidentially admissible and able to support a swift investigation and hopefully prosecution.\n​When you’re feeling ready to say something, protection, safety and justice are just a few taps away."
        }
      },
      terms: {
        tableOfContent: {
          title: {
            [lanIds.en]: "Table of Contents"
          }
        },
        userTypes: {
          title: {
            [lanIds.en]: "Please Select your User Type"
          },
          types: {
            medicalProfessional: {
              title: {
                [lanIds.en]: "Medical Professional"
              },
              titleMobile: {
                [lanIds.en]: "I am a Medical Professional"
              },
              description: {
                [lanIds.en]:
                  "If you’re a health professional please read these terms"
              }
            },
            policeOfficer: {
              title: {
                [lanIds.en]: "Police Officer"
              },
              titleMobile: {
                [lanIds.en]: "I am a Police Officer"
              },
              description: {
                [lanIds.en]:
                  "If you’re a Police Officer please read these terms"
              }
            },
            victim: {
              title: {
                [lanIds.en]: "Victims"
              },
              description: {
                [lanIds.en]: "If you’re a Victim please read these terms"
              }
            },
            others: {
              title: {
                [lanIds.en]: "Individual"
              },
              titleMobile: {
                [lanIds.en]: "I am an Individual"
              },
              description: {
                [lanIds.en]: "If you’re an individual please read these terms"
              }
            }
          }
        }
      },
      privacy: {
        title: {
          [lanIds.en]: "Privacy Policy"
        }
      }
    },
    cookiePolicy: {
      title: {
        [lanIds.en]: "Cookie Policy"
      },
      policy: {
        title: {
          [lanIds.en]: (
            <span>
              <HighlightColor>KULPA</HighlightColor> MOBILE APPLICATION AND
              WEBSITE COOKIE POLICY
            </span>
          )
        },
        policies: {
          cookiesUsed: {
            title: {
              [lanIds.en]: "Cookies Used"
            },
            description: {
              text: {
                [lanIds.en]: "We may use the following cookies:"
              },
              list: [
                {
                  listName: {
                    [lanIds.en]: "Strictly necessary cookies."
                  },
                  description: {
                    [lanIds.en]:
                      "These are cookies that enable you to log on to secure areas of the App or the Website. We use these to make sure our digital services work correctly (e.g. not glitching or crashing) and to ensure that both  the App and the Website meet our users needs;"
                  }
                },
                {
                  listName: {
                    [lanIds.en]: "Analytical cookies."
                  },
                  description: {
                    [lanIds.en]:
                      "These allow us to recognise and count the number of App or Website users and to understand how users navigate around the App or the Website when using it;"
                  }
                },
                {
                  listName: {
                    [lanIds.en]: "Functionality cookies."
                  },
                  description: {
                    [lanIds.en]:
                      "These are used to recognise you if you return to the App or the Website, and enable us to remember your preferences; and"
                  }
                },
                {
                  listName: {
                    [lanIds.en]: "Targeting cookies."
                  },
                  description: {
                    [lanIds.en]:
                      "These cookies record your visit to the App or the Website and the links that you follow and track your responses to particular forms that can help us to ensure that we present you with the most relevant content in the future."
                  }
                }
              ]
            }
          },
          purpose: {
            title: {
              [lanIds.en]: "Purpose of Use"
            },
            description: {
              text: {
                [lanIds.en]: "Amongst other things, we may use cookies to:"
              },
              clarification: {
                [lanIds.en]:
                  "Some cookies will be erased when you close the App or the Website. Others will stay longer, and in some cases indefinitely. These will stay on your Device so that they are then when you come back to the App or the Website."
              },
              list: [
                {
                  [lanIds.en]: "Ascertain what type of Device you are using;"
                },
                {
                  [lanIds.en]: "Estimate usage patterns;"
                },
                {
                  [lanIds.en]:
                    "Source location of users (if location services are switched on for the App or if your browser settings allow websites to track your physical location);"
                },
                {
                  [lanIds.en]:
                    "Store information about your preferences, so as to customise your App or the Website experience;"
                },
                {
                  [lanIds.en]:
                    "Deliver adverts that are more relevant to you, as well as limit the number of times you see a particular advertisement to measure the effectiveness of marketing campaigns;"
                },
                {
                  [lanIds.en]:
                    "Recognise when you return to the App or the Website; and"
                },
                {
                  [lanIds.en]:
                    "Remember details when you complete online forms, e.g. contact number, so that you do not have to re-provide this data or re-complete forms if you pause them part way through."
                }
              ]
            }
          },
          rights: {
            title: {
              [lanIds.en]: "Your Rights"
            },
            description: {
              text: {
                [lanIds.en]:
                  "We will ask you on your first visit to the App or the Website whether you consent to cookies being used. You can turn analytical, functionaility and targeting cookies off or on at any time by settings within the App or through the settings on the Website or in your web-browser. You can also turn off tracking functions in your broswer or Device settings. You may be able to block other cookies (including strictly necessary cookies), but this may affect your ability to use all or parts of the Website or App."
              }
            }
          },
          changes: {
            title: {
              [lanIds.en]: "Changes to this Policy"
            },
            description: [
              {
                [lanIds.en]:
                  "We may review this policy from time to time and any changes will be notified to you by posting an updated version on the App or the Website and by notifying you next time you start, open or login to the App or the Website."
              },
              {
                [lanIds.en]:
                  "We recommend you regularly check for changes and review this policy whenever you visit the App or the Website. If you do not agree with any aspect of the updated policy you must immediately notify us and cease using our services."
              }
            ]
          },
          contactUs: {
            title: {
              [lanIds.en]: "Contact Us"
            },
            description: [
              {
                [lanIds.en]:
                  "If you have any queries about this policy, our Terms of Use, or the way we use your cookies, please contact us using the contact details below."
              },
              {
                [lanIds.en]:
                  "David Scoggins, Data Protection Officer and Head of Compliance, Innovation House, 19 Staple Gardens, Winchester, Hampshire, SO23 8SR (admin@anatomap.com)."
              }
            ]
          }
        }
      },
      permissions: {
        title: {
          [lanIds.en]: "Cookies Settings"
        },
        shortDescription: {
          first: {
            [lanIds.en]:
              "We use cookies to improve your experience as a user. By using kulpa’s services you are agreeing to our "
          },
          cookieLinkText: {
            [lanIds.en]: "Cookie Policy."
          },
          second: {
            [lanIds.en]:
              "We’ll always need to use Strictly necessary cookies so that our services work correctly, but you can change your other cookie "
          },
          preferencesLink: {
            [lanIds.en]: "preferences "
          },
          third: {
            [lanIds.en]: "at any time."
          },
          consent: {
            [lanIds.en]: "I consent to all cookies."
          },
          customize: {
            [lanIds.en]: "Set my own preferences. "
          }
        },
        cookies: {
          necessary: {
            title: {
              [lanIds.en]: "Allow Strictly necessary cookies"
            },
            description: {
              [lanIds.en]:
                "These are cookies that enable you to log on to secure areas of the Platform or the Website. We use these to make sure our digital services work correctly (e.g. not glitching or crashing) and to ensure that both the Platform and the Website meet our users needs; To use our services these must be turned on."
            }
          },
          analytical: {
            title: {
              [lanIds.en]: "Allow Analytical cookies"
            },
            description: {
              [lanIds.en]:
                "These allow us to recognise and count the number of Platform or Website users and to understand how users navigate around the Platform or the Website when using it."
            }
          },
          functionality: {
            title: {
              [lanIds.en]: "Allow Functionality cookies"
            },
            description: {
              [lanIds.en]:
                "These are used to recognise you if you return to the Platform or the Website, and enable us to remember your preferences."
            }
          },
          targeting: {
            title: {
              [lanIds.en]: "Allow Targeting cookies."
            },
            description: {
              [lanIds.en]:
                "These cookies record your visit to the Platform or the Website and the links that you follow and track your responses to particular forms that can help us to ensure that we present you with the most relevant content in the future."
            }
          }
        }
      },
      banner: {
        goToPoliciy: {
          [lanIds.en]:
            "You can change your cookies settings at any time. Our cookie policy is "
        },
        policyLink: {
          [lanIds.en]: "here."
        }
      }
    },
    pressRelease: {
      title: {
        [lanIds.en]: "Press Releases"
      },
      description: {
        [lanIds.en]:
          "Use the Kulpa app to record and securely store legally admissible forensic evidence in just a few taps.​ ​It’s up to you, if and when, you choose to formally report the crime and submit the evidence to the police.\n​But whether you choose to submit the evidence you capture with this app, today, tomorrow or in a few years time, it will be evidentially admissible and able to support a swift investigation and hopefully prosecution.\n​When you’re feeling ready to say something, protection, safety and justice are just a few taps away."
      }
    },
    certifications: {
      title: {
        [lanIds.en]: "ACCREDITATION CERTIFICATES"
      },
      description: {
        [lanIds.en]:
          "Use the Kulpa app to record and securely store legally admissible forensic evidence in just a few taps.​ ​It’s up to you, if and when, you choose to formally report the crime and submit the evidence to the police.\n​But whether you choose to submit the evidence you capture with this app, today, tomorrow or in a few years time, it will be evidentially admissible and able to support a swift investigation and hopefully prosecution.\n​When you’re feeling ready to say something, protection, safety and justice are just a few taps away."
      },
      certificationsList: [
        {
          title: {
            [lanIds.en]:
              "Kulpa is BS 10008 Evidential Weight and Legal Admissibility of Electronic Information certified"
          },
          description: {
            [lanIds.en]:
              "BS 10008 is the British Standard that that outlines best practice for the implementation and operation of electronic information management systems, including storage and transfer of information. It is designed to help you verify and authenticate all your information to avoid the legal pitfalls of information storage. BS 10008 outlines the best practice for transferring electronic information between systems and migrating paper records to digital files. It also gives guidelines for managing the availability."
          },
          date: new Date("01/05/2021"),
          badge: {
            [lanIds.en]: loadBadge(lanIds.en, "10008.png")
          }
        },
        {
          title: {
            [lanIds.en]: "Kulpa ISO/IEC 27001 Information Security Management"
          },
          description: {
            [lanIds.en]:
              "Understanding and/or applying the requirements of any standard to your business isn't always a straightforward process. BSI has helped train and certify countless organizations around the world to embeded an effective ISO/IEC 27001 ISMS. And you can benefit from our experience too with our ISO/IEC 27001 training courses and certification."
          },
          date: new Date("01 /05/2021"),
          badge: {
            [lanIds.en]: loadBadge(lanIds.en, "27001.png")
          }
        }
      ]
    }
  },
  cantFind: {
    title: {
      [lanIds.en]: "Can’t find what you’re looking for?"
    },
    description: {
      [lanIds.en]:
        "We’ll help you find the answer or connect you with an advisor."
    },
    contactUs: {
      [lanIds.en]: "Contact Us"
    }
  },
  emergencyHeader: {
    bannerText: {
      [lanIds.en]: "Is someone in immediate danger?"
    },
    call: {
      [lanIds.en]: "Call "
    }
  },
  common: {
    login: {
      [lanIds.en]: "Login"
    },
    appName: {
      [lanIds.en]: "Kulpa"
    },
    appPhrase: {
      [lanIds.en]: "Putting justice in your hands"
    },
    copyright: {
      [lanIds.en]: "© Copyright Anatomap Ltd."
    },
    forms: {
      email: {
        [lanIds.en]: "Email"
      },
      submit: {
        [lanIds.en]: "Submit"
      }
    },
    supportedBy: {
      [lanIds.en]: "Supported By"
    },
    poweredBy: {
      [lanIds.en]: "Powered By"
    },
    userTypes: {
      medicalProfessional: {
        [lanIds.en]: "Medical Professional"
      },
      policeOfficer: {
        [lanIds.en]: "Police Officer"
      },
      victim: {
        [lanIds.en]: "Victim"
      },
      relative: {
        [lanIds.en]: "Relative"
      },
      witness: {
        [lanIds.en]: "Witness"
      },
      other: {
        [lanIds.en]: "Individual"
      }
    }
  },
  formGeneric: {
    errors: {
      tooLong: {
        [lanIds.en]: "Field is too long."
      },
      required: {
        [lanIds.en]: "Mandatory field"
      },
      invalidEmail: {
        [lanIds.en]: "Field is not a valid Email."
      },
      invalidPhone: {
        [lanIds.en]: "The phone number is not valid."
      },
      error: {
        [lanIds.en]: "An error occurred. Please review your submission."
      }
    }
  }
};

export const getTextSingle = textLanguage => {
  const lan = defaultLan;
  const text = textLanguage[lan];
  return text;
};

export const getGroup = (...args) => {
  const textGroup = get(language, [...args]);
  return textGroup;
};
