import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

// Assets
import lockIcon from "../../assets/icons/lock.svg";
import captureIcon from "../../assets/icons/capture-ico.svg";

// Components
import AccreditationSectionInfo from "./AccreditationSectionInfo";
import HighlightColor from "../../components/HighlightColor/index";
import Text, { types } from "../../components/Text/Text";

// Utils
import url, { getRoute } from "../../config/urls";
import { useIsInViewportStatic } from "../../hooks/useIsInViewport";
import { getCertificates } from "../../state/Certificates/actions";

const useStyles = makeStyles(theme => ({
  accreditationRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(10),
    padding: theme.spacing(16, 0, 0),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(8, 0)
    }
  },
  miniTitle: {
    textAlign: "center",
    [theme.breakpoints.only("md")]: {
      fontSize: 56,
      width: 1004
    }
  },
  subtitle: {
    color: "#454F63",
    [theme.breakpoints.down("md")]: {
      fontSize: 22
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      width: "100%",
      maxWidth: "340px"
    }
  },
  accreditations: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  }
}));

const icons = {
  bs10008: captureIcon,
  iso27001: lockIcon
};

const AccreditationSection = ({ className, ...rest }) => {
  const { certificationsList } = useSelector(s => s.certificates);
  const classes = useStyles();
  const ref = useRef();
  const isInViewport = useIsInViewportStatic(ref, { threshold: 0.5 });
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const dispatch = useDispatch();

  // Language TODO when localazy is appplied

  useEffect(() => {
    dispatch(getCertificates());
  }, []);

  return (
    <div className={classes.accreditationRoot} {...rest}>
      <Text
        type={types.miniSectionTitle}
        className={classes.miniTitle}
        component="h2"
      >
        <HighlightColor>Kulpa</HighlightColor> Is Internationally Accredited
      </Text>
      <Text
        type={types.titleDescription}
        className={classes.subtitle}
        component="p"
      >
        so you can be assured that your evidence is safe and sound
      </Text>
      <div className={classes.accreditations} ref={ref}>
        {certificationsList &&
          certificationsList.map((item, index) => (
            <Zoom
              key={item.id}
              timeout={500}
              in={isMobile || isInViewport}
              style={{ transitionDelay: `${index * 500}ms` }}
            >
              <AccreditationSectionInfo
                icon={icons[item.id]}
                title="Our UKAS accreditation for"
                certificate={item.title}
                description={item.lead}
                badgeIcon={item.imageURL}
                badgeLink={`${getRoute(url.certifications)}#pageStart`}
                file={item.pdfURL}
              />
            </Zoom>
          ))}
      </div>
    </div>
  );
};

export default AccreditationSection;
