import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

// Assets
import evidenceLawyerBg from "../../assets/backgrounds/evidence-lawyer-bg.png";
import reportBg from "../../assets/backgrounds/report-incident-bg.png";
import storingMobileBg from "../../assets/backgrounds/storing-evidence-mobile-bg.png";

// Components
import Section from "../Section/Section";
import Text, { types } from "../Text/Text";

// Config
import colors from "../../config/colors";

// Contents
import StoringEvidenceDesktop from "./StoringEvidenceDesktop";
import HomeSubsectionResponsive from "../HomeSubsection/HomeSubsectionResponsive";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 1500,
    paddingBottom: 100,
    paddingRight: "2.4vw",
    paddingLeft: "2.4vw",
    [theme.breakpoints.down("md")]: {
      minHeight: 1000,
      paddingBottom: 40
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      paddingBottom: 0
    }
  },
  mainTitle: {
    maxWidth: 1344,
    textTransform: "capitalize",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: 896
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 530
    }
  },
  brandSpan: {
    color: colors.lightBlue
  }
}));

const subsections = [
  {
    id: "doNothing",
    heading: "Do Nothing",
    description: "Your data will not be shared with anyone.",
    title: "That’s entirely your choice",
    bigText: true,
    descriptions: [
      <>
        <span>Kulpa</span> is your safe space to collect evidence at your own
        pace. All data captured or uploaded to the app is securely stored on
        internationally accredited cloud servers (ISO/IEC 270001). Your data
        will not be shared unless you choose to submit it to the police or
        another agency via the app.
      </>,
      "You can delete your data at any time if you haven’t submitted it to the police, but once deleted, it cannot be retrieved. You have complete control over your data and its use, and it will always be safe and secure."
    ],
    mobileBg: storingMobileBg
  },
  {
    id: "lawyer",
    heading: "Send The Evidence To A Lawyer",
    title: "So that they can help you take a civil action",
    bigText: true,
    description: "They can help you take a civil action.",
    descriptions: [
      <>
        You can quickly and easily send your <span>kulpa</span> data to a lawyer
        or solicitor. Just enter their email and mobile phone number. They will
        receive an encrypted file with all the evidence, along with an
        exhibiting statement for use in any civil legal process. They can then
        assist you in obtaining any necessary civil orders, such as protective
        or restraining orders.
      </>
    ],
    background: evidenceLawyerBg
  },
  {
    id: "injuries",
    heading: "Report The Incident To The Police",
    description: "Police will receive immediately usable evidence.",
    title:
      "So that they can quickly safeguard you and hopefully prosecute the offender",
    descriptions: [
      <>
        When you use <span>kulpa</span> to report a crime, the police receive a
        complete package of immediately usable evidence. This includes all your
        stored evidence, an automatically generated evidential statement, and a
        metadata report. The evidence is verified and legally admissible.
      </>,
      <>
        The police can use this comprehensive information to promptly interview
        the suspect and build a compelling case, leading to quicker charging
        decisions and better protection for victims. Since <span>kulpa</span>{" "}
        captures all necessary metadata, a physical download of your entire
        device is usually not required. This ensures your personal information
        remains private and secure.
      </>
    ],
    background: reportBg
  }
];

const mainTitle = element => (
  <>Securely storing evidence on the {element} cloud gives you options</>
);

const StoringEvidenceSection = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Section className={classes.root}>
      <Text
        type={types.miniSectionTitle}
        className={classes.mainTitle}
        component="h2"
      >
        {mainTitle(<span className={classes.brandSpan}>kulpa</span>)}
      </Text>
      {isMobile ? (
        <HomeSubsectionResponsive subsections={subsections} />
      ) : (
        <StoringEvidenceDesktop subsections={subsections} />
      )}
    </Section>
  );
};

export default StoringEvidenceSection;
