import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Material
import { makeStyles, Modal } from "@material-ui/core";

// State
import { closeVideoModal } from "../../state/TutorialVideos/actions";

// Config
import videoConfig from "../../config/videoConfig";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  video: {
    width: "70vw",
    maxHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    outline: "none"
  },
  videoContent: {
    width: "100%",
    height: "100%",
    maxWidth: "70vw",
    maxHeight: "90vh",
    aspectRatio: "16 / 9",
    border: "none",
    borderRadius: "4px",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1920px",
      maxHeight: "1080px"
    }
  },
  options: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingTop: theme.spacing(3)
  }
}));

const getQueryParams = data => {
  const params = new URLSearchParams();
  Object.keys(data).forEach(key => params.append(key, data[key]));
  return params.toString();
};

const src = `${videoConfig.url}&${getQueryParams(videoConfig.params)}`;

const VideoModal = ({ ...rest }) => {
  const { isModalOpen } = useSelector(s => s.tutorialVideos);
  const classes = useStyles();
  const dispatch = useDispatch();

  if (!isModalOpen) return null;

  const onClose = () => dispatch(closeVideoModal());

  return (
    <Modal
      {...rest}
      className={classes.modal}
      open={isModalOpen}
      onClose={onClose}
    >
      <div className={classes.video}>
        <iframe
          className={classes.videoContent}
          src={src}
          title={videoConfig.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
    </Modal>
  );
};

export default VideoModal;
