/* eslint-disable react/no-array-index-key */
import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";

// Contents
import Text, { types } from "../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%"
  },
  content: {
    marginBottom: theme.spacing(4),
    maxWidth: "80vw",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw"
    }
  },
  image: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: "100%",
    maxWidth: 328,
    margin: "0 auto",
    display: "block"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  itemNumberWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  accentBox: {
    height: "5px",
    width: "40px",
    backgroundColor: colors.lightBlue
  },
  headingNumber: {
    marginBottom: theme.spacing(0),
    fontSize: "42px"
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  description: {
    marginBottom: theme.spacing(1)
  }
}));

const HomeSubsectionResponsive = ({ subsections }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {subsections.map(
        ({ heading, title, descriptions, mobileBg, id }, index) => (
          <div key={id} className={classes.content}>
            {mobileBg && (
              <img src={mobileBg} alt="" className={classes.image} />
            )}
            <div className={classes.titleWrapper}>
              <div className={classes.itemNumberWrapper}>
                <Text
                  type={types.subsectionHeadingMobile}
                  className={classes.headingNumber}
                >
                  {index + 1}.
                </Text>
                <div className={classes.accentBox} />
              </div>
              <Text type={types.subsectionHeadingMobile}>{heading}</Text>
            </div>
            <Text type={types.subsectionTitleMobile} className={classes.title}>
              {title}
            </Text>
            {descriptions.map((data, i) => (
              <Text
                key={i}
                type={types.subsectionDescriptionMobile}
                className={classes.description}
              >
                {data}
              </Text>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default HomeSubsectionResponsive;
