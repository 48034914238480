import React, { forwardRef } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text, { types } from "../../components/Text/Text";
import { ButtonBase } from "../../../node_modules/@material-ui/core/index";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(10),
    width: 550,
    maxWidth: "35%",
    flex: "1 1",
    [theme.breakpoints.down("md")]: {
      width: 456,
      flex: "initial",
      maxWidth: "initial",
      margin: theme.spacing(5, 0)
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 600,
      width: "100%"
    }
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  icon: {
    height: 90,
    [theme.breakpoints.down("xs")]: {
      height: 70
    }
  },
  badgeIcon: {
    height: 105,
    [theme.breakpoints.down("md")]: {
      height: 80
    },
    [theme.breakpoints.down("xs")]: {
      height: 70
    }
  },
  badgeButton: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2)
    }
  }
}));

const AccreditationSectionInfo = forwardRef(
  (
    {
      className,
      icon,
      title,
      certificate,
      description,
      badgeIcon,
      badgeLink,
      file,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({ icon, badgeIcon });

    return (
      <div className={`${classes.root} ${className}`} {...rest} ref={ref}>
        <div className={classes.imageWrapper}>
          <img className={classes.icon} src={icon} alt="icon" />
          <ButtonBase
            className={classes.badgeButton}
            component="a"
            href={file}
            target="_blank"
          >
            <img
              className={classes.badgeIcon}
              src={badgeIcon}
              alt="badgeIcon"
            />
          </ButtonBase>
        </div>
        <Text type={types.accreditationInfoTitle}>{title}</Text>
        <Text type={types.accreditationInfoCertificate}>{certificate}</Text>
        <Text type={types.accreditationInfoDescription}>{description}</Text>
      </div>
    );
  }
);

export default AccreditationSectionInfo;
