import React, { useRef } from "react";
import clsx from "clsx";

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";

// Components
import Text, { types } from "../Text/Text";

// Images
import kulpaLogo from "../../assets/backgrounds/start-journey.png";
import backgroundImage from "../../assets/backgrounds/light-blue-background.png";

// Langauge and config
import { externalRoutes } from "../../config/urls";
import { language, getTextSingle } from "../../languages/Languages";
import styles from "../../config/styles";
import { useIsInViewportStatic } from "../../hooks/useIsInViewport";
import { analyticsClassNames } from "../../config/googleAnalitycs";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "87%",
    margin: `0px ${styles.values.standardMargin}`,
    marginTop: theme.spacing(14),
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    ...styles.mixins.icon,
    backgroundImage: `url(${kulpaLogo})`,
    backgroundSize: "cover",
    color: "white",
    borderRadius: 12,
    padding: "5vw",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      padding: "65px 0 45px",
      alignItems: "flex-start"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: 0,
      marginTop: theme.spacing(4),
      borderRadius: 0,
      backgroundImage: `url(${backgroundImage})`
    }
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 560,
    maxWidth: 590,
    marginRight: "9vw",
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
      maxWidth: "initial",
      width: 363,
      marginRight: 242
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      minWidth: 0,
      marginRight: theme.spacing(0)
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  contactUs: {
    fontSize: 24,
    borderRadius: 10,
    height: 84,
    width: 435,
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(4),
    marginTop: 0,
    fontFamily: "Maven Pro",
    fontWeight: 500,
    background: "white",
    color: theme.palette.secondary.main,
    boxShadow: "0px 3px 66px #00000029",
    "&:hover": {
      background: "white",
      color: theme.palette.secondary.main
    },
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
      width: 267,
      height: 52,
      margin: 0
    },
    [theme.breakpoints.down("sm")]: {
      height: 68,
      width: "60%",
      fontSize: 18
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      maxWidth: 380,
      margin: "0 auto"
    }
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      lineHeight: 1,
      marginBottom: theme.spacing(1.5)
    }
  },
  description: {
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      width: "28ch",
      maxWidth: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22
    }
  }
}));

const StartJourney = ({ className, children, ...rest }) => {
  const classes = useStyles();
  const { title, description, getApp } = language.pages.home.journey;
  const ref = useRef();
  const isInViewport = useIsInViewportStatic(ref, {
    treshold: 0.3
  });
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Fade in={isMobile || isInViewport} timeout={500}>
      <div className={`${classes.root} ${className}`} {...rest} ref={ref}>
        <div className={classes.info}>
          <Text className={classes.title} type={types.miniSectionTitle}>
            {getTextSingle(title)}
          </Text>
          <Text className={classes.description} type={types.titleDescription}>
            {getTextSingle(description)}
          </Text>
        </div>
        <Button
          className={clsx(classes.contactUs, analyticsClassNames.downloadLink)}
          color="primary"
          variant="contained"
          href={externalRoutes.webapp}
          component="a"
          rel="noreferrer"
          target="_blank"
        >
          {getTextSingle(getApp)}
        </Button>
      </div>
    </Fade>
  );
};

export default StartJourney;
