import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../config/colors";

export const types = {
  titleLabel: "titleLabel",
  titleBig: "titleBig",
  sectionTitle: "sectionTitle",
  sectionSubTitle: "sectionSubTitle",
  subTitleBig: "subTitleBig",
  titleDescription: "titleDescription",
  titleInformation: "titleInformation",
  miniSectionTitle: "miniSectionTitle",
  small: "small",
  tutorialTitle: "tutorialTitle",
  tutorialDescription: "tutorialDescription",
  question: "question",
  answer: "answear",
  supportLineTitle: "supportLineTitle",
  supportLineDescription: "supportLineDescription",
  supportLineCall: "supportLineCall",
  getAppDescription: "getAppDescription",
  empowerBanner: "empowerBanner",
  requestBoxTitle: "requestBoxTitle",
  requestBoxDescription: "requestBoxDescription",
  cantFindTitle: "cantFindTitle",
  cantFindDescription: "cantFindDescription",
  reminderTextIC: "reminderTextIC",
  // Footer
  smaller: "smaller",
  smallInverse: "smallInverse",
  contentFooterApp: "contentFooterApp",
  // Home
  campaignTitle: "campaignTitle",
  accreditationInfoTitle: "accreditationInfoTitle",
  accreditationInfoCertificate: "accreditationInfoCertificate",
  accreditationInfoDescription: "accreditationInfoDescription",
  // (Home) Subsections
  buttonLinkTitle: "buttonLinkTitle",
  buttonLinkDescription: "buttonLinkDescription",
  subsectionHeading: "subsectionHeading",
  subsectionTitle: "subsectionTitle",
  subsectionHeadingMobile: "subsectionHeadingMobile",
  subsectionSubtitle: "subsectionSubtitle",
  subsectionTitleMobile: "subsectionTitleMobile",
  subsectionDescription: "subsectionDescription",
  subsectionDescriptionMobile: "subsectionDescriptionMobile",
  // Forms
  fieldError: "fieldError",
  inputLabel: "inputLabel",
  inputDescription: "inputDescription",
  noticeTitle: "noticeTitle",
  noticeDescription: "noticeDescription",
  // Terms
  tableSubElement: "tableSubElement",
  termsElementTitle: "termsElementTitle",
  // Press Release
  pressPreviewTitle: "pressPreviewTitle",
  pressTitle: "pressTitle",
  pressDescription: "pressDescription"
};

export const useStyles = makeStyles(theme => ({
  [types.smaller]: {
    fontSize: 17,
    fontFamily: "Maven Pro",
    lineHeight: 2,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      lineHeight: 1.2
    }
  },
  [types.small]: {
    fontSize: 17,
    fontFamily: "Maven Pro",
    lineHeight: 1
  },
  [types.smallInverse]: {
    fontSize: 10,
    lineHeight: 1,
    color: colors.white,
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    }
  },
  [types.titleLabel]: {
    fontSize: 18,
    fontFamily: "Gibson",
    fontWeight: "bold",
    letterSpacing: "0.05em;",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30
    }
  },
  [types.contentFooterApp]: {
    fontSize: 17,
    fontFamily: "Gibson",
    color: colors.grey
  },
  [types.titleBig]: {
    fontSize: 56,
    fontFamily: "Gibson",
    fontWeight: "bold",
    lineHeight: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30
    }
  },
  [types.subTitleBig]: {
    fontSize: 28,
    fontFamily: "Gibson",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    }
  },
  [types.titleDescription]: {
    fontSize: 28,
    whiteSpace: "pre-line",
    /* color: "#454F63", */
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: 18
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      fontSize: 17,
      textAlign: "center"
    },
    [theme.breakpoints.up("xl")]: {
      width: "100% !important",
      fontSize: 42
    }
  },
  [types.titleInformation]: {
    fontSize: "1vw",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      fontSize: 17,
      textAlign: "center"
    },
    [theme.breakpoints.up("xl")]: {
      width: "100% !important",
      fontSize: 32
    }
  },
  [types.sectionTitle]: {
    fontSize: 56,
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: 800,
    lineHeight: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "3vw"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28
    }
  },
  [types.sectionSubTitle]: {
    fontSize: 100,
    color: theme.palette.secondary.main,
    fontFamily: "Montserrat Black",
    fontWeight: 900,
    textTransform: "uppercase",
    lineHeight: "1.3em",
    [theme.breakpoints.down("md")]: {
      fontSize: "8vw"
    }
  },
  [types.miniSectionTitle]: {
    fontSize: 56,
    fontFamily: "Montserrat",
    fontWeight: 800,
    textAlign: "left",
    lineHeight: 1.2,
    marginBottom: 14,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 36
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      textAlign: "center"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28
    }
  },
  [types.pressPreviewTitle]: {
    fontSize: 32,
    fontFamily: "Gibson",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 22
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18
    }
  },
  [types.tutorialTitle]: {
    fontSize: "3vw",
    fontFamily: "Gibson",
    fontWeight: "bold",
    lineHeight: 1.1
  },
  [types.tutorialDescription]: {
    fontSize: "1.5vw",
    fontFamily: "Gibson",
    marginTop: "0.5vw"
  },
  [types.question]: {
    fontSize: 22,
    fontFamily: "Gibson",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18
    }
  },
  [types.answer]: {
    fontSize: 18,
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    }
  },
  [types.supportLineTitle]: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: 1.1
  },
  [types.supportLineDescription]: {
    fontSize: 17,
    color: colors.grey,
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      color: theme.palette.primary.main
    }
  },
  [types.supportLineCall]: {
    fontSize: 18,
    fontFamily: "Gibson",
    fontWeight: "bold"
  },
  [types.fieldError]: {
    fontSize: 15,
    fontFamily: "Gibson",
    color: colors.error,
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.6vw"
    }
  },
  [types.termsElementTitle]: {
    fontSize: 20,
    fontFamily: "Gibson",
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  [types.tableSubElement]: {
    fontSize: 17,
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    }
  },
  [types.getAppDescription]: {
    fontSize: 17
  },
  [types.empowerBanner]: {
    fontSize: 72,
    lineHeight: 1.1,
    textAlign: "center",
    fontFamily: "Montserrat Black",
    fontWeight: 900,
    textTransform: "uppercase",
    color: colors.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "5vw"
    }
  },
  [types.pressTitle]: {
    fontSize: 24,
    fontFamily: "Open Sans",
    fontWeight: 600,
    margin: theme.spacing(4, 0),
    letterSpacing: 0,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0)
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
      fontSize: 20
    }
  },
  [types.pressDescription]: {
    fontSize: 17,
    fontFamily: "Open Sans",
    fontWeight: 600,
    margin: theme.spacing(2, 0),
    letterSpacing: 0,
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0)
    }
  },
  [types.requestBoxTitle]: {
    fontSize: 28,
    textAlign: "center",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    minHeight: 80,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      minHeight: 0
    }
  },
  [types.requestBoxDescription]: {
    fontSize: 22,
    width: "80%",
    minHeight: 70,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      minHeight: 0
    }
  },
  [types.cantFindTitle]: {
    fontSize: 31,
    fontWeight: "bold",
    color: colors.riverBed,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  [types.cantFindDescription]: {
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      marginTop: theme.spacing(1)
    }
  },
  [types.campaignTitle]: {
    fontSize: "5vw",
    fontFamily: "Montserrat Black",
    fontWeight: 900,
    lineHeight: 1.1
  },
  [types.inputLabel]: {
    fontSize: 15,
    fontFamily: "Gibson",
    fontWeight: "bold",
    color: "#454F63",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.6vw"
    }
  },
  [types.inputDescription]: {
    fontSize: 15,
    color: colors.grey,
    fontFamily: "Gibson",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.6vw"
    }
  },
  [types.noticeTitle]: {
    fontSize: 36,
    fontWeight: "bold",
    fontFamily: "Gibson",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24
    }
  },
  [types.noticeDescription]: {
    fontSize: 18,
    fontFamily: "Gibson",
    width: "70%",
    textAlign: "center",
    maxWidth: 1600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      width: "90%"
    }
  },
  [types.accreditationInfoTitle]: {
    fontSize: 36,
    fontFamily: "Montserrat",
    fontWeight: 600,
    textAlign: "center",
    color: "#454F63",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      lineHeight: 1.2
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  [types.accreditationInfoCertificate]: {
    fontSize: 26,
    fontFamily: "Montserrat",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      lineHeight: 1.2
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  [types.accreditationInfoDescription]: {
    fontSize: 22,
    textAlign: "center",
    color: colors.grey,
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
      marginTop: theme.spacing(2)
    }
  },
  [types.buttonLinkTitle]: {
    fontFamily: "Gibson",
    fontWeight: "bold",
    fontSize: 17,
    color: colors.riverBed,
    [theme.breakpoints.down("md")]: {
      fontSize: 11
    }
  },
  [types.buttonLinkDescription]: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 17,
    color: colors.santasGray
  },
  [types.buttonLinkDescription]: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 17,
    color: colors.santasGray,
    [theme.breakpoints.down("md")]: {
      fontSize: 11
    }
  },
  [types.subsectionHeading]: {
    fontFamily: "Maven Pro",
    fontWeight: "500",
    color: colors.lightBlue,
    fontSize: 32,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    }
  },
  [types.subsectionTitle]: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 36,
    lineHeight: 1.2,
    color: colors.mineShaft,
    [theme.breakpoints.down("md")]: {
      fontSize: 28
    }
  },
  [types.subsectionSubtitle]: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 33,
    lineHeight: 1.3,
    color: colors.mineShaft,
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    }
  },
  [types.subsectionDescription]: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 18,
    color: colors.mineShaft,
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    }
  },
  [types.subsectionHeadingMobile]: {
    fontFamily: "Maven Pro",
    fontWeight: 700,
    color: colors.lightBlue,
    fontSize: 32,
    lineHeight: 1.2,
    textTransform: "capitalize"
  },
  [types.subsectionTitleMobile]: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 17,
    lineHeight: 1.4,
    color: colors.mineShaft
  },
  [types.subsectionDescriptionMobile]: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 1.6,
    color: colors.mineShaft
  },
  [types.reminderTextIC]: {
    fontSize: 28,
    fontWeight: 600,
    fontFamily: "Gibson",
    color: "#454F63",
    marginTop: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
      marginTop: 2
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
      marginTop: 2.5
    }
  }
}));

const variants = {
  heading1: "h2",
  heading2: "h3",
  heading3: "h3",
  heading4: "h3",
  subtitle1: "subtitle1",
  subtitle2: "subtitle1",
  contentLabel: "body1",
  contentLabel2: "body1",
  contentLabel3: "body1",
  contentBold: "body1",
  valueLabel: "body1",
  valueLabel2: "body1",
  body: "body1",
  large: "body1"
};

const Text = ({ type, classes, ...props }) => {
  const classesOwn = useStyles();

  return (
    <Typography
      classes={{ ...classes, root: classesOwn[type] }}
      variant={variants[type]}
      {...props}
    />
  );
};

Text.propTypes = {
  type: PropTypes.string
};

Text.defaultProps = {
  type: ""
};

export default Text;
